import Link from 'next/link'
import { Bullet } from 'ui/components/bullet'
import React from 'react'

export function LoginFooter() {
  return (
    <footer className="flex justify-center gap-6 text-sm font-medium text-white">
      <Link
        href={'https://formacion.ninja/politica-de-privacidad'}
        className={'cursor-pointer hover:underline'}>
        {'Privacidad'}
      </Link>
      <Bullet color={'#fff'} className={'my-auto h-2 w-2'} />
      <Link
        href={'https://formacion.ninja/aviso-legal/aviso-legal'}
        className={'cursor-pointer hover:underline'}>
        {'Legal'}
      </Link>
      <Bullet color={'#fff'} className={'my-auto h-2 w-2'} />
      <Link
        href={'https://formacion.ninja/politica-de-cookies'}
        className={'cursor-pointer hover:underline'}>
        {'Cookies'}
      </Link>
    </footer>
  )
}
