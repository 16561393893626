import { twMerge } from 'tailwind-merge'

export function Bullet({
  color,
  className,
}: {
  color: string
  className?: string
}) {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={twMerge('rounded-full', className)}
    />
  )
}
